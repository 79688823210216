<template>
	<div class="LeftMain NoSelect" :class="{LeftMainWidthOne:!simple,LeftMainWidthTwo:simple}" ref="LeftNavMainBox" @scroll="NavScroll">
    <div class="logo" @click="clickNav(1,0,'BusinessHomePage',null)"><img :src="logoImg"></div>
    <ul class="LeftNavMain" ref="LeftNavMain">
      <li v-for="(item,index) in navdatas" v-bind:key="index" :class="[{LeftNavMainActiveOne:item.active&&item.rank == 2},{LeftNavMainActiveTwo:item.active&&item.rank == 1}]" @click="clickNav(1,index,item.url,null,$t('c.LeftNav.'+item.title+''), item)">
        <i></i>
        <span class="icon iconfont LeftNavMainIcon" :class="item.fonticon"></span>
        <span v-if="!simple" class="LeftNavMainTitle" v-text="$t('c.LeftNav.'+item.title+'')"></span>
        <span v-if="item.rank == 1 && !simple" class="icon iconfont iconicon-test25"></span>
        <div class="SonNav" v-if="item.SonDatas && item.active && !simple">
          <i></i>
          <a v-for="(value,six) in item.SonDatas" v-bind:key="six" v-text="$t('c.LeftNav.'+value.title+'')" @click.stop="clickNav(2,index,value.url,six,$t('c.LeftNav.'+value.title+''), value)" :class="{SonNavActive:value.active}"></a>
        </div>
       </li>
    </ul>

    <!-- 滚动条遮罩 -->
    <div class="LeftScollBar" :class="[{headmainPOne:!simple},{headmainPTwo:simple}]"></div>

    <!-- 自定义滚动条 -->
    <div class="scrollbarbox">
      <p class="scrollbar" :style="{opacity:sbarOpacity,height:sbarHeight+'px',top:sbarTop+'px',left:sbarLeft+'px'}"></p>
    </div>

	</div>
</template>

<script>
var self;
import axios from 'axios'

export default {
  name: 'LeftNav',
  props:["simple","isLogin"], //左边导航栏收起和展开
  data () {
    return {
      role:null, //角色
      navdatas: [], //导航内容
      logoImg:"static/images/logo.png", //logo
      sbarOpacity:0, //滚动条透明度
      sbarHeight:0, //滚动条高度
      sbarTop:0 ,//滚动条距离顶部的位置
      sbarLeft:177 //滚动条距离左边的位置
    }
  },
  created () {
  
    self = this;
    self.role = localStorage.getItem("wwmxt_Role");

    //不同角色的导航内容
    if(self.role == 10){
      self.navdatas = [
        {fonticon: 'iconicon-test', title: "HomePage", rank: '2', url: 'AadminHomePage', active: false,SonDatas: null},
        // {fonticon: 'iconicon-test47', title: "Financial", rank: '2', url: 'Financial', active: false,SonDatas: null},
        {fonticon: 'iconicon-test48', title: "Organization", rank: '2', url: 'Organization', active: false, SonDatas: null},
        {fonticon: 'iconicon-test49', title: "Business", rank: '2', url: 'Business', active: false, SonDatas: null},
        {fonticon: 'iconicon-test37', title: "TrademarkAudit", rank: '2', url: 'TrademarkAudit', active: false, SonDatas: null},
        {fonticon: 'iconicon-test1', title: "ProductAudit", rank: '2', url: 'ProductAudit', active: false, SonDatas: null},
        {fonticon: 'iconicon-test1', title: "ProductManage", rank: '2', url: 'ProductManage', active: false, SonDatas: null},
        {fonticon: 'iconicon-test51', title: "Message", rank: '2', url: 'Message', active: false, SonDatas: null},
        {fonticon: 'iconicon-test12', title: "Register", rank: '2', url: 'Register', active: false, SonDatas: null},
        {fonticon: 'iconicon-test13', title: "UniversalCode", rank: '2', url: 'UniversalCode', active: false, SonDatas: null},
        {fonticon: 'iconicon-test3', title: "CodeAllot", rank: '2', url: 'CodeAllot', active: false, SonDatas: null},
        {fonticon: 'iconicon-test45', title: "barCode", rank: '2', url: 'barCode', active: false, SonDatas: null},
        {fonticon: 'iconicon-test41', title: "BarCodePacket", rank: '2', url: 'BarCodePacket', active: false, SonDatas: null},
        {fonticon: 'iconicon-test46', title: "searchRecord", rank: '2', url: 'searchRecord', active: false, SonDatas: null},
        {fonticon: 'icontubiaozhizuomoban', title: "TemplateSetting", rank: '1', url: null, active: false, SonDatas: [{title: 'TemplateDatas', url: 'TemplateDatas', active: false},{title: 'TemplateList', url: 'TemplateList', active: false}]},
        // {fonticon: 'iconicon-test50', title: "Push", rank: '1', url: null, active: false, SonDatas: [{title: 'Push', url: 'Push', active: false},{title: 'PushList', url: 'PushList', active: false}]},
        // {fonticon: 'iconicon-test52', title: "Invoice", rank: '1', url: null, active: false, SonDatas: [{title: 'InvoiceSetting', url: 'InvoiceSetting', active: false},{title: 'InvoiceList', url: 'InvoiceList', active: false}]},
        // {fonticon: 'iconicon-test53', title: "Developer", rank: '1', url: null, active: false, SonDatas: [{title: 'DeveloperList', url: 'DeveloperList', active: false},{title: 'Blacklist', url: 'DeveloperBlacklist', active: false}]},
        {fonticon: 'iconicon-test16', title: "Setting", rank: '1', url: null, active: false, SonDatas: [{title: 'CreateCodeAudit', url: 'CreateCodeAudit', active: false},{title: 'FunctionSetting', url: 'FunctionSetting', active: false}
        // ,{title: 'DocsSetting', url: 'DocsSetting', active: false}
        ,{title: 'UserAgreement', url: 'UserAgreement', active: false}]},
      ]
    }else if(self.role == 20){
      self.navdatas = [
        // {fonticon: 'iconicon-test', title: "HomePage", rank: '2', url: 'ORGHomePage', active: false,SonDatas: null},
        // {fonticon: 'iconicon-test47', title: "Financial", rank: '2', url: 'ORGFinancial', active: false,SonDatas: null},
        {fonticon: 'iconicon-test49', title: "Business", rank: '2', url: 'ORGBusiness', active: false, SonDatas: null},
        {fonticon: 'iconicon-test4', title: "MaterialManage", rank: '1', url: null, active: false, SonDatas: [{title: 'ImagesSpace', url: 'ImagesSpace', active: false},{title: 'AudioSpace', url: 'AudioSpace', active: false},{title: 'VideosSpace', url: 'VideosSpace', active: false}]},
        {fonticon: 'iconicon-test16', title: "Account", rank: '1', url: null, active: false, SonDatas: [{title: 'BasicInfo', url: 'ORGBasicInfo', active: false},{title: 'SecuritySettings', url: 'SecuritySettings', active: false}]},
      ]
      if(localStorage.getItem("wwmxt_Rootid") == "1001"){
        self.navdatas.splice(1, 0, {fonticon: 'iconicon-test45', type:'kanban', title: "Kanban", rank: '2', url: 'ORGKanban', active: false,SonDatas: null});
        self.navdatas.splice(2, 0, {fonticon: 'iconicon-test1', title: "ProductManage", rank: '2', url: 'ORGProductManage', active: false,SonDatas: null});
      }else if(["1002", "1009"].includes(localStorage.getItem("wwmxt_Rootid"))){
        self.navdatas.splice(1, 0, {fonticon: 'iconicon-test45', type:'kanban', title: "Kanban", rank: '2', url: 'ORGCKKanban?orgid='+localStorage.getItem("wwmxt_Rootid"), active: false,SonDatas: null});
      }else if(localStorage.getItem("wwmxt_Rootid") == "1004"){
        self.navdatas.splice(1, 0, {fonticon: 'iconicon-test1', title: "ProductManage", rank: '2', url: 'ORGProductManage', active: false,SonDatas: null});
        self.navdatas.splice(2, 0, {fonticon: 'iconicon-test45', title: "BatchManage", rank: '2', url: 'ORGBatchManage', active: false, SonDatas: null});
      }
      if(localStorage.getItem("wwmxt_Rootid") == "1011"){
        self.navdatas.splice(1, 0, {fonticon: 'iconicon-test1', title: "ProductManage", rank: '2', url: 'ProductManage', active: false,SonDatas: null});
      }else{
        self.navdatas.splice(3, 0, {fonticon: 'iconicon-test3', title: "CodeAllot", rank: '2', url: 'CodeAllot', active: false, SonDatas: null});
      }

    }else if(self.role == 30){
      let uid = localStorage.getItem("wwmxt_Rootid");
      self.navdatas = [
        {fonticon: 'iconicon-test', title: "HomePage", rank: '2', url: 'BusinessHomePage', active: false,SonDatas: null},
        {fonticon: 'iconicon-test1', title: "ProductManage", rank: '2', url: 'ProductManage', active: false, SonDatas: null},
        {fonticon: 'iconicon-test45', title: "BatchManage", rank: '2', url: 'BatchManage', active: false, SonDatas: null},
        {fonticon: 'iconicon-test3', title: "CodeActive", rank: '2', url: 'CodeActive', active: false, SonDatas: null},
        {fonticon: 'iconicon-test41', title: "CodePacket", rank: '2', url: 'CodePacket', active: false, SonDatas: null},
        {fonticon: 'iconicon-test37', title: "Brand", rank: '2', url: 'Brand', active: false, SonDatas: null},
        {fonticon: 'iconicon-test38', title: "Market", rank: '2', url: 'Market', active: false, SonDatas: null},
        // {fonticon: 'icontubiaozhizuomoban', title: "Promotion", rank: '1', url: null, active: false, SonDatas: [{title: 'PromotionSet', url: 'PromotionSet', active: false},{title: 'UserStats', url: 'UserStats', active: false},{title: 'WinningRecord', url: 'WinningRecord', active: false},{title: 'WithdrawalRecord', url: 'WithdrawalRecord', active: false},{title: 'PrizeExchange', url: 'PrizeExchange', active: false}]},
        {fonticon: 'iconicon-test39', title: "ProcessManage", rank: '1', url: null, active: false, SonDatas: [{title: 'ProcessList', url: 'ProcessList', active: false},{title: 'ProcessAllot', url: 'ProcessAllot', active: false}]},
        {fonticon: 'iconicon-test12', title: "DealerManage", rank: '1', url: null, active: false, SonDatas: [{title: 'DealerList', url: 'DealerList', active: false},{title: 'DealerShipping', url: 'DealerShipping', active: false}]},
        {fonticon: 'iconicon-test4', title: "MaterialManage", rank: '1', url: null, active: false, SonDatas: [{title: 'ImagesSpace', url: 'ImagesSpace', active: false},{title: 'AudioSpace', url: 'AudioSpace', active: false},{title: 'VideosSpace', url: 'VideosSpace', active: false}]},
        {fonticon: 'iconicon-test5', title: "Statistics", rank: '1', url: null, active: false, SonDatas: [{title: 'CodeQuery', url: 'CodeQuery', active: false},{title: 'GroupQuery', url: 'GroupQuery', active: false},{title: 'FleeingGoodsList', url: 'FleeingGoodsList', active: false},{title: 'ScanQRStats', url: 'ScanQRStats', active: false},{title: 'ScanQRRecords', url: 'ScanQRRecords', active: false},{title: 'QueryQRRecords', url: 'QueryQRRecords', active: false},{title: 'MobileQRRecords', url: 'MobileQRRecords', active: false}]},
        {fonticon: 'iconicon-test16', title: "Account", rank: '1', url: null, active: false, SonDatas: [{title: 'BasicInfo', url: 'BasicInfo', active: false},{title: 'SecuritySettings', url: 'SecuritySettings', active: false}]},
      ]
      if(uid.substring(0,4) != 1001){
        self.navdatas.splice(2,0,{fonticon: 'iconicon-test2', title: "CodeManage", rank: '2', url: 'CodeManage', active: false, SonDatas: null});
      }
      if(uid.substring(0,4) == 1004){
        self.navdatas.splice(8,0,{fonticon: 'iconicon-test51', title: "QualityAppeal", rank: '2', url: 'Message', active: false, SonDatas: null});
      }
      if(['1002','1009'].includes(uid.substring(0,4))){
        self.navdatas.splice(4,0,{fonticon: 'iconicon-test1', title: "DeviceManage", rank: '2', url: 'DeviceManage', active: false, SonDatas: null});
        self.navdatas.splice(5,0,{fonticon: 'iconicon-test41', title: "Fields", rank: '2', url: 'Licensor', active: false, SonDatas: null});
        self.navdatas.splice(7,0,{fonticon: 'iconicon-test46', title: "Replace", rank: '2', url: 'Replace', active: false, SonDatas: null});
      }
      if(uid == 100010109 || uid == 100010002){
        self.navdatas.splice(7,0,{fonticon: 'iconicon-test37', title: "ShhfBrand", rank: '2', url: 'ShhfBrand', active: false, SonDatas: null});
        self.navdatas.splice(8,0,{fonticon: 'iconicon-test37', title: "Licensor", rank: '2', url: 'Licensor', active: false, SonDatas: null});
      }
    }else if(self.role == 40){
      self.navdatas = [
        // {fonticon: 'iconicon-test', title: "HomePage", rank: '2', url: 'BrancHomePage', active: false,SonDatas: null},
        {fonticon: 'iconicon-test1', title: "StockManage", rank: '2', url: 'BrancStockManage', active: false,SonDatas: null},
        {fonticon: 'iconicon-test39', title: "ProcessAllot", rank: '2', url: 'ProcessAllot', active: false, SonDatas: null},
        {fonticon: 'iconicon-test45', title: "BatchManage", rank: '2', url: 'BatchManage', active: false, SonDatas: null},
        {fonticon: 'iconicon-test12', title: "DealerShipping", rank: '2', url:  'DealerShipping', active: false, SonDatas: null}
      ]
    }else if(self.role == 50){
      self.navdatas = [
        // {fonticon: 'iconicon-test', title: "HomePage", rank: '2', url: 'BrancHomePage', active: false,SonDatas: null},
        {fonticon: 'iconicon-test1', title: "StockManage", rank: '2', url: 'BrancStockManage', active: false,SonDatas: null},
        {fonticon: 'iconicon-test12', title: "DealerManage", rank: '1', url: null, active: false, SonDatas: [
          {title: 'DealerList', url: 'DealerList', active: false},
          {title: 'DeliveryRecord', url: 'DeliveryRecord', active: false},
          {title: 'ReceiptRecord', url: 'ReceiptRecord', active: false}
        ]}
      ]
    }

  },
  mounted () {
    if(!self.isLogin){
      //判断历史激活导航栏
      self.leftNavActive();
    }

    //监听浏览器返回
    window.addEventListener("popstate", function(e) {
      //先清除所有激活状态并找到栈顶页
      for(let item in self.navdatas){
        self.navdatas[item].active = false;
        if(self.navdatas[item].SonDatas){
          for(let value in self.navdatas[item].SonDatas){
            if(self.navdatas[item].SonDatas[value].url == self.$route.name){
              localStorage.setItem("wwmxt_HistoryURL",self.$route.name);
            }
            self.navdatas[item].SonDatas[value].active = false;
          }
        }else{
          if(self.navdatas[item].url == self.$route.name){
            localStorage.setItem("wwmxt_HistoryURL",self.$route.name);
          }
        }
      }
      self.leftNavActive();
    }, false);
  },
  watch :{
    simple:function(){
      if(!self.simple){
        self.logoImg = "../../static/images/logo.png"
        self.sbarLeft = 177;
        self.ScrollBarStyle();
      }else{
        self.logoImg = "../../static/images/logoTwo.png"
        self.sbarLeft = 57;
        self.ScrollBarStyle();
      }
    }
  },
  methods: {
    leftNavActive () {
      var hyURL = localStorage.getItem("wwmxt_HistoryURL");
      if(hyURL && hyURL != "HomePage"){
        //屏蔽公共模块影响样式
        if(self.$route.name != "Notifications" && self.$route.name != "Question"  && self.$route.name != "ChangePassword"){
          for(let item in self.navdatas){
            if(self.navdatas[item].url == hyURL){
              self.navdatas[item].active = true;
            }
            if(self.navdatas[item].SonDatas){
              for(let value in self.navdatas[item].SonDatas){
                if(self.navdatas[item].SonDatas[value].url == hyURL){
                  self.navdatas[item].active = true;
                  self.navdatas[item].SonDatas[value].active = true;
                }
              }
            }
          }
        }
      }else{
        self.navdatas[0].active = true;
      }

      //滚动条样式
      self.ScrollBarStyle();
    },
    clickNav (te,ix,url,six,name, item) {

      if(item && item.type === 'kanban'){
        const _url = '/#/' + item.url;
        window.open(_url);
        return;
      }

      if(url == "Market"){
        var url = window.location.href; /* 获取完整URL */
        var fix = url.indexOf("//")+2;
        // console.log(url.substring(fix,fix+3))

        let confirm = window.confirm("是否确认前往营销活动页面？")
        if(confirm){
          window.location.href = "https://sale.oid.plus/myCoffers/?source="+url.substring(fix,fix+3)+"&token="+localStorage.getItem("wwmxt_Token");
          // window.location.href = "http://localhost:8081?source="+url.substring(fix,fix+3)+"&token="+localStorage.getItem("wwmxt_Token");
        }
        return;
      }

      for(let item in self.navdatas){
        if(te == 1){
          if(!self.navdatas[ix].SonDatas){
            self.navdatas[item].active = false;
            for(let value in self.navdatas[item].SonDatas){
              self.navdatas[item].SonDatas[value].active = false;
            }
            if(url == "StockManage"){
              var stock = {id:localStorage.getItem("wwmxt_Rootid"),type:"Merchant",name:name};
              localStorage.setItem("wwmxt_stock",JSON.stringify(stock));
            }
          }else{
            if(self.navdatas[item].rank == 1 && ix != item){
              self.$emit('changeSimple',{simple:false});
              self.navdatas[item].active = false;
            }
          }
        }else if(te == 2){
          self.navdatas[item].active = false;
          for(let value in self.navdatas[item].SonDatas){
            self.navdatas[item].SonDatas[value].active = false;
          }
          self.navdatas[ix].SonDatas[six].active = true;
        }
      }
      if(self.navdatas[ix].active){
        self.navdatas[ix].active = false;
      }else{
        self.SonNavHeight = 50;
        self.navdatas[ix].active = true;
      }
      if(url){
        self.GoToPage(url); // 路由跳转
        localStorage.setItem("wwmxt_HistoryURL",url);
      }

      //滚动条样式
      self.ScrollBarStyle();
    },
    OpenLeftNavPage (te,url) {
      //先清除所有激活状态
      for(let item in self.navdatas){
        self.navdatas[item].active = false;
        if(self.navdatas[item].SonDatas){
          for(let value in self.navdatas[item].SonDatas){
            self.navdatas[item].SonDatas[value].active = false;
          }
        }
      }
      if(te){
        localStorage.setItem("wwmxt_HistoryURL",url);
        self.leftNavActive();
      }
      self.GoToPage(url); // 路由跳转

      //滚动条样式
      self.ScrollBarStyle();
    },
    ScrollBarStyle () {
      if(self.$refs.LeftNavMain){
        setTimeout(function(){
          var leftnav = document.documentElement.clientHeight-60;
          if(self.$refs.LeftNavMain){
            var leftmain = self.$refs.LeftNavMain.offsetHeight;
            if (leftmain > leftnav) {
              self.sbarOpacity = 1;
              self.sbarHeight = leftnav - (leftmain - leftnav) + 60;
            }else{
              self.sbarOpacity = 0;
            }
          }
        },10);
      }
    },
    NavScroll (e) {
      self.sbarTop = self.$refs.LeftNavMainBox.scrollTop;
    }
  }
}
</script>

<style scoped>
  .LeftMain{
    position: fixed;
    left:0;top:0;
    height:100%;
    background-color: #1D2B36;
    z-index: 98;
    overflow-y: scroll;
  }
  .LeftMainWidthOne{
    width:200px;
  }
  .LeftMainWidthTwo{
    width:80px;
  }
  .logo{
    cursor: pointer;
    height:60px;
    overflow: hidden;
    box-shadow:0 5px 10px rgba(0,0,0,0.1);
  }
  .logo img{
    height:40px;
    display: table;
    margin-left:10px;
    margin-top:10px;
  }
  .LeftScollBar{
    position: fixed;
    top:0;bottom:0;
    background-color: #EFF4F8;
    width: 20px;
    z-index: 98;
  }
  .headmainPOne{
    left:180px;
  }
  .headmainPTwo{
    left:60px;
  }

  .LeftNavMain{
    line-height: 55px;
    color: #E4ECF2;
    font-size:12px;
  }
  .LeftNavMain li{
    cursor: pointer;
  }
  .LeftNavMain li:hover{
    background-color: #162029;
  }
  .LeftNavMainIcon{
    padding-left:20px;
  }
  .LeftNavMainTitle{
    padding-left:5px;
    position: relative;
    top:-1px;
  }
  .LeftNavMainActiveOne{
    position: relative;
    color:#3670FC;
    background-color: #162029;
  }
  .LeftNavMainActiveOne i,.LeftNavMainActiveTwo i{
    position: absolute;
    left:0;top:0;
    width:3px;
    height:55px;
    background-color: #3670FC;
  }
  .LeftNavMainActiveTwo{
    position: relative;
    background-color: #162029;
  }
  .LeftNavMainActiveTwo > .LeftNavMainIcon{
    color:#3670FC;
  }
  .iconicon-test25{
    font-size:8px;
    position: relative;
    float: right;
    right:20px;
    top:2px;
  }
  .SonNav{
    overflow: hidden;
    background-color: #162029;
  }
  .SonNav i{
    position: absolute;
    left:0;top:0;
    width:3px;
    height:100%;
    background-color: #3670FC;
  }
  .SonNav a{
    display: block;
    padding-left:45px;
  }
  .SonNavActive{
    color:#3670FC;
  }

  /* 自定义滚动条 */
  .scrollbarbox {
    position: fixed;
    top: 0;
    height: 100%;
    width: 3px;
    z-index: 99;
  }
  .scrollbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    border-radius: 10px;
    background-color: #4EB9E9;
    height: 100%;
    background: -webkit-linear-gradient(#3595FF, #00BCD4);
    background: -o-linear-gradient(#3595FF, #00BCD4);
    background: -moz-linear-gradient(#3595FF, #00BCD4);
    background: linear-gradient(#3595FF, #00BCD4);
  }
</style>
