/* 公共 */
import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/common/Login'
import Signin from '@/components/common/Signin'
import Notifications from '@/components/common/Notifications'
import Question from '@/components/common/Question'
import SecuritySettings from '@/components/common/SecuritySettings'
import NotificationsDetail from '@/components/common/NotificationsDetail'
import ForgetPassword from '@/components/common/ForgetPassword'
import ChangePassword from '@/components/common/ChangePassword'
import SafetyProblem from '@/components/common/SafetyProblem'

//管理员
import AadminHomePage from '@/components/admin/HomePage'
import Financial from '@/components/admin/Financial'
import Organization from '@/components/admin/Organization'
import OrganizationEdit from '@/components/admin/OrganizationEdit'
import Business from '@/components/admin/Business'
import BusinessEdit from '@/components/admin/BusinessEdit'
import BusinessDetail from '@/components/admin/BusinessDetail'
import JuniorBusiness from '@/components/admin/JuniorBusiness'
import TrademarkAudit from '@/components/admin/TrademarkAudit'
import ProductAudit from '@/components/admin/ProductAudit'
import TemplateList from '@/components/admin/TemplateList'
import TemplateDatas from '@/components/admin/TemplateDatas'
import Push from '@/components/admin/Push'
import PushList from '@/components/admin/PushList'
import Message from '@/components/admin/Message'
import UniversalCode from '@/components/admin/UniversalCode'
import BarCode from '@/components/admin/BarCode'
import BarCodePacket from '@/components/admin/CodePacket'
import InvoiceSetting from '@/components/admin/InvoiceSetting'
import InvoiceList from '@/components/admin/InvoiceList'
import DeveloperList from '@/components/admin/DeveloperList'
import DeveloperBlacklist from '@/components/admin/DeveloperBlacklist'
import CreateCodeAudit from '@/components/admin/CreateCodeAudit'
import FunctionSetting from '@/components/admin/FunctionSetting'
import DocsSetting from '@/components/admin/DocsSetting'
import DocsDatasSetting from '@/components/admin/DocsDatasSetting'
import UserAgreement from '@/components/admin/UserAgreement'
import CodeAllot from '@/components/admin/CodeAllot'
import OIDRegister from '@/components/admin/OIDRegister'
import SearchRecord from '@/components/admin/SearchRecord'

//组织
import ORGHomePage from '@/components/organization/HomePage'
import ORGKanban from '@/components/organization/Kanban'
import ORGCKKanban from '@/components/organization/CKKanban'
import ORGFinancial from '@/components/organization/Financial'
import ORGBusiness from '@/components/organization/Business'
import ORGBasicInfo from '@/components/organization/BasicInfo'
import ORGProductManage from '@/components/organization/ProductManage'
import ORGProductEdit from '@/components/organization/ProductEdit'
import ORGBatchManage from '@/components/organization/BatchManage'

//企业
import BusinessHomePage from '@/components/business/HomePage'
import ProductManage from '@/components/business/ProductManage'
import ProductEdit from '@/components/business/ProductEdit'
import DeviceManage from '@/components/business/DeviceManage'
import DeviceEdit from '@/components/business/DeviceEdit'
import CodeManage from '@/components/business/CodeManage'
import MobileTemplate from '@/components/business/MobileTemplate'
import MobileTemplateEdit from '@/components/business/MobileTemplateEdit'
import BatchManage from '@/components/business/BatchManage'
import QualityInformation from '@/components/business/QualityInformation'
import CodeActive from '@/components/business/CodeActive'
import PromotionSet from '@/components/business/PromotionSet'
import WinningRecord from '@/components/business/WinningRecord'
import WithdrawalRecord from '@/components/business/WithdrawalRecord'
import PrizeExchange from '@/components/business/PrizeExchange'
import UserStats from '@/components/business/UserStats'
import CodePacket from '@/components/business/CodePacket'
import ProcessList from '@/components/business/ProcessList'
import ProcessAllot from '@/components/business/ProcessAllot'
import ProcessEdit from '@/components/business/ProcessEdit'
import DealerList from '@/components/business/DealerList'
import DealerEdit from '@/components/business/DealerEdit'
import DealerShipping from '@/components/business/DealerShipping'
import UserManage from '@/components/business/UserManage'
import StockManage from '@/components/business/StockManage'
import StockDetails from '@/components/business/StockDetails'
import ManualStockRecord from '@/components/business/ManualStockRecord'
import ImagesSpace from '@/components/business/ImagesSpace'
import VideosSpace from '@/components/business/VideosSpace'
import StaticPacket from '@/components/business/StaticPacket'
import AudioSpace from '@/components/business/AudioSpace'
import CodeQuery from '@/components/business/CodeQuery'
import GroupQuery from '@/components/business/GroupQuery'
import FleeingGoodsList from '@/components/business/FleeingGoodsList'
import ScanQRStats from '@/components/business/ScanQRStats'
import ScanQRRecords from '@/components/business/ScanQRRecords'
import QueryQRRecords from '@/components/business/QueryQRRecords'
import MobileQRRecords from '@/components/business/MobileQRRecords'
import BatchQuery from '@/components/business/BatchQuery'
import BasicInfo from '@/components/business/BasicInfo'
import FunctionPackage from '@/components/business/FunctionPackage'
import BillingManagement from '@/components/business/BillingManagement'
import IncomeDisbursement from '@/components/business/IncomeDisbursement'
import InvoiceManage from '@/components/business/InvoiceManage'
import InvoiceAddress from '@/components/business/InvoiceAddress'
import SetInvoiceTitle from '@/components/business/SetInvoiceTitle'
import Brand from '@/components/business/Brand'
import BrandEdit from '@/components/business/BrandEdit'
import DemoProcess from '@/components/business/DemoProcess'
import ShhfBrand from '@/components/business/ShhfBrand'
import ShhfLicensor from '@/components/business/ShhfLicensor'
import Replace from '@/components/business/Replace'

//经销商、工序网点
import BrancHomePage from '@/components/branch/HomePage'
import BrancStockManage from '@/components/branch/StockManage'
import BrancStockDetails from '@/components/branch/StockDetails'
import BrancManualStockRecord from '@/components/branch/ManualStockRecord'
import DeliveryRecord from '@/components/branch/DeliveryRecord'
import ReceiptRecord from '@/components/branch/ReceiptRecord'


Vue.use(Router)

export default new Router({
  routes: [
    {//公共
      path: '/',
      name: 'Login',
      component: Login
    },{
      path: '/Signin',
      name: 'Signin',
      component: Signin
    },{
      path: '/Notifications',
      name: 'Notifications',
      component: Notifications
    },{
      path: '/Question',
      name: 'Question',
      component: Question
    },{
      path: '/ChangePassword',
      name: 'ChangePassword',
      component: ChangePassword
    },{
      path: '/SafetyProblem',
      name: 'SafetyProblem',
      component: SafetyProblem
    },{
      path: '/ForgetPassword',
      name: 'ForgetPassword',
      component: ForgetPassword
    },{//管理员
      path: '/AadminHomePage',
      name: 'AadminHomePage',
      component: AadminHomePage
    },{
      path: '/Financial',
      name: 'Financial',
      component: Financial
    },{
      path: '/Organization',
      name: 'Organization',
      component: Organization
    },{
      path: '/OrganizationEdit',
      name: 'OrganizationEdit',
      component: OrganizationEdit
    },{
      path: '/Business',
      name: 'Business',
      component: Business
    },{
      path: '/BusinessEdit',
      name: 'BusinessEdit',
      component: BusinessEdit
    },{
      path: '/BusinessDetail',
      name: 'BusinessDetail',
      component: BusinessDetail
    },{
      path: '/JuniorBusiness',
      name: 'JuniorBusiness',
      component: JuniorBusiness
    },{
      path: '/TrademarkAudit',
      name: 'TrademarkAudit',
      component: TrademarkAudit
    },{
      path: '/ProductAudit',
      name: 'ProductAudit',
      component: ProductAudit
    },{
      path: '/TemplateList',
      name: 'TemplateList',
      component: TemplateList
    },{
      path: '/TemplateDatas',
      name: 'TemplateDatas',
      component: TemplateDatas
    },{
      path: '/Push',
      name: 'Push',
      component: Push
    },{
      path: '/PushList',
      name: 'PushList',
      component: PushList
    },{
      path: '/Message',
      name: 'Message',
      component: Message
    },{
      path: '/UniversalCode',
      name: 'UniversalCode',
      component: UniversalCode
    },{
      path: '/BarCode',
      name: 'BarCode',
      component: BarCode
    },{
      path: '/BarCodePacket',
      name: 'BarCodePacket',
      component: BarCodePacket
    },{
      path: '/InvoiceSetting',
      name: 'InvoiceSetting',
      component: InvoiceSetting
    },{
      path: '/InvoiceList',
      name: 'InvoiceList',
      component: InvoiceList
    },{
      path: '/DeveloperList',
      name: 'DeveloperList',
      component: DeveloperList
    },{
      path: '/DeveloperBlacklist',
      name: 'DeveloperBlacklist',
      component: DeveloperBlacklist
    },{
      path: '/CreateCodeAudit',
      name: 'CreateCodeAudit',
      component: CreateCodeAudit
    },{
      path: '/FunctionSetting',
      name: 'FunctionSetting',
      component: FunctionSetting
    },{
      path: '/DocsSetting',
      name: 'DocsSetting',
      component: DocsSetting
    },{
      path: '/DocsDatasSetting',
      name: 'DocsDatasSetting',
      component: DocsDatasSetting
    },{
      path: '/UserAgreement',
      name: 'UserAgreement',
      component: UserAgreement
    },{
      path: '/CodeAllot',
      name: 'CodeAllot',
      component: CodeAllot
    },{
      path: '/Register',
      name: 'OIDRegister',
      component: OIDRegister
    },{
      path: '/SearchRecord',
      name: 'SearchRecord',
      component: SearchRecord
    },{//组织
      path: '/ORGHomePage',
      name: 'ORGHomePage',
      component: ORGHomePage
    },{
      path: '/ORGKanban',
      name: 'ORGKanban',
      component: ORGKanban
    },{
      path: '/ORGCKKanban',
      name: 'ORGCKKanban',
      component: ORGCKKanban
    },{
      path: '/ORGFinancial',
      name: 'ORGFinancial',
      component: ORGFinancial
    },{
      path: '/ORGBusiness',
      name: 'ORGBusiness',
      component: ORGBusiness
    },{
      path: '/ORGBasicInfo',
      name: 'ORGBasicInfo',
      component: ORGBasicInfo
    },{
      path: '/ORGProductEdit',
      name: 'ORGProductEdit',
      component: ORGProductEdit
    },{
      path: '/ORGProductManage',
      name: 'ORGProductManage',
      component: ORGProductManage
    },{
      path: '/ORGBatchManage',
      name: 'ORGBatchManage',
      component: ORGBatchManage
    },{//商户
      path: '/BusinessHomePage',
      name: 'BusinessHomePage',
      component: BusinessHomePage
    },{
      path: '/ProductManage',
      name: 'ProductManage',
      component: ProductManage
    },{
      path: '/ProductEdit',
      name: 'ProductEdit',
      component: ProductEdit
    },{
      path: '/DeviceManage',
      name: 'DeviceManage',
      component: DeviceManage
    },{
      path: '/DeviceEdit',
      name: 'DeviceEdit',
      component: DeviceEdit
    },{
      path: '/MobileTemplate',
      name: 'MobileTemplate',
      component: MobileTemplate
    },{
      path: '/MobileTemplateEdit',
      name: 'MobileTemplateEdit',
      component: MobileTemplateEdit
    },{
      path: '/CodeManage',
      name: 'CodeManage',
      component: CodeManage
    },{
      path: '/BatchManage',
      name: 'BatchManage',
      component: BatchManage
    },{
      path: '/QualityInformation',
      name: 'QualityInformation',
      component: QualityInformation
    },{
      path: '/CodeActive',
      name: 'CodeActive',
      component: CodeActive
    },{
      path: '/PromotionSet',
      name: 'PromotionSet',
      component: PromotionSet
    },{
      path: '/WinningRecord',
      name: 'WinningRecord',
      component: WinningRecord
    },{
      path: '/WithdrawalRecord',
      name: 'WithdrawalRecord',
      component: WithdrawalRecord
    },{
      path: '/PrizeExchange',
      name: 'PrizeExchange',
      component: PrizeExchange
    },{
      path: '/UserStats',
      name: 'UserStats',
      component: UserStats
    },{
      path: '/CodePacket',
      name: 'CodePacket',
      component: CodePacket
    },{
      path: '/ProcessList',
      name: 'ProcessList',
      component: ProcessList
    },{
      path: '/ProcessAllot',
      name: 'ProcessAllot',
      component: ProcessAllot
    },{
      path: '/ProcessEdit',
      name: 'ProcessEdit',
      component: ProcessEdit
    },{
      path: '/DealerList',
      name: 'DealerList',
      component: DealerList
    },{
      path: '/DealerEdit',
      name: 'DealerEdit',
      component: DealerEdit
    },{
      path: '/DealerShipping',
      name: 'DealerShipping',
      component: DealerShipping
    },{
      path: '/UserManage',
      name: 'UserManage',
      component: UserManage
    },{
      path: '/StockManage',
      name: 'StockManage',
      component: StockManage
    },{
      path: '/StockDetails',
      name: 'StockDetails',
      component: StockDetails
    },{
      path: '/ManualStockRecord',
      name: 'ManualStockRecord',
      component: ManualStockRecord
    },{
      path: '/ImagesSpace',
      name: 'ImagesSpace',
      component: ImagesSpace
    },{
      path: '/AudioSpace',
      name: 'AudioSpace',
      component: AudioSpace
    },{
      path: '/VideosSpace',
      name: 'VideosSpace',
      component: VideosSpace
    },{
      path: '/StaticPacket',
      name: 'StaticPacket',
      component: StaticPacket
    },{
      path: '/CodeQuery',
      name: 'CodeQuery',
      component: CodeQuery
    },{
      path: '/GroupQuery',
      name: 'GroupQuery',
      component: GroupQuery
    },{
      path: '/FleeingGoodsList',
      name: 'FleeingGoodsList',
      component: FleeingGoodsList
    },{
      path: '/ScanQRStats',
      name: 'ScanQRStats',
      component: ScanQRStats
    },{
      path: '/ScanQRRecords',
      name: 'ScanQRRecords',
      component: ScanQRRecords
    },{
      path: '/QueryQRRecords',
      name: 'QueryQRRecords',
      component: QueryQRRecords
    },{
      path: '/MobileQRRecords',
      name: 'MobileQRRecords',
      component: MobileQRRecords
    },{
      path: '/BatchQuery',
      name: 'BatchQuery',
      component: BatchQuery
    },{
      path: '/BasicInfo',
      name: 'BasicInfo',
      component: BasicInfo
    },{
      path: '/FunctionPackage',
      name: 'FunctionPackage',
      component: FunctionPackage
    },{
      path: '/BillingManagement',
      name: 'BillingManagement',
      component: BillingManagement
    },{
      path: '/IncomeDisbursement',
      name: 'IncomeDisbursement',
      component: IncomeDisbursement
    },{
      path: '/InvoiceManage',
      name: 'InvoiceManage',
      component: InvoiceManage
    },{
      path: '/InvoiceAddress',
      name: 'InvoiceAddress',
      component: InvoiceAddress
    },{
      path: '/SetInvoiceTitle',
      name: 'SetInvoiceTitle',
      component: SetInvoiceTitle
    },{
      path: '/SecuritySettings',
      name: 'SecuritySettings',
      component: SecuritySettings
    },{
      path: '/NotificationsDetail',
      name: 'NotificationsDetail',
      component: NotificationsDetail
    },{
      path: '/Brand',
      name: 'Brand',
      component: Brand
    },{
      path: '/DemoProcess',
      name: 'DemoProcess',
      component: DemoProcess
    },{
      path: '/BrandEdit',
      name: 'BrandEdit',
      component: BrandEdit
    },{
      path: '/ShhfBrand',
      name: 'ShhfBrand',
      component: ShhfBrand
    },{
      path: '/Licensor',
      name: 'Licensor',
      component: ShhfLicensor
    },{
      path: '/Replace',
      name: 'Replace',
      component: Replace
    },{//经销商、工序网点
      path: '/BrancHomePage',
      name: 'BrancHomePage',
      component: BrancHomePage
    },{
      path: '/BrancStockManage',
      name: 'BrancStockManage',
      component: BrancStockManage
    },{
      path: '/BrancStockDetails',
      name: 'BrancStockDetails',
      component: BrancStockDetails
    },{
      path: '/BrancManualStockRecord',
      name: 'BrancManualStockRecord',
      component: BrancManualStockRecord
    },{
      path: '/DeliveryRecord',
      name: 'DeliveryRecord',
      component: DeliveryRecord
    },{
      path: '/ReceiptRecord',
      name: 'ReceiptRecord',
      component: ReceiptRecord
    }
  ]
})
