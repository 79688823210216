//公共
export const c = {
  Language: {
    name: '中文'
  },
  Login:{
    ItemUniqueCode: 'Item Unique Code', //链接OID
    CodeDescribe: 'In the era of the Internet of all things, we are the first "code"!',  //链接OID描述
    UserName: 'Email/UserName', //邮箱/用户名
    Password: 'Password', //密码
    Rememb: 'Rememb', //记住密码
    SignIn: 'Sign in', //注册账号
    SignUp: 'Sign up', //登录
    Logging: 'Logging', //登录中
    ForgetPassword: 'Forget Password', //忘记密码
    Hint: 'Browser Incompatibility', //提示
    CustomerServiceDownload: 'Customer Service Download', //客服端下载
  },
  HeadBar:{
    HomePage: 'HomePage', //首页
    GoLogin: 'Login',  //前往登录
    FunctionPackage: 'Function Package', //生码套餐
    BillingManagement: 'Billing Management', //费用
    Service: 'Service', //在线客服
    Documents: 'Documents', //操作文档
    BasicInfo: 'Basic Info', //基本信息
    ChangePassword: 'Change Password', //修改密码
    SecuritySettings: 'Security Settings', //安全设置
    Logout: 'Logout', //退出登录
    ViewMore: 'View More' //查看更多
  },
  LeftNav:{
    //管理员
    Financial: 'Financial', //财务管理
    Kanban: 'Kanban', //看板模式
    Organization: 'Organization', //组织管理
    Business: 'Business', //商户管理
    TrademarkAudit: 'Trademark Audit', //商标审核
    ProductAudit: 'Product Audit', //产品审核
    UniversalCode: 'Universal Code', //万能码生成
    barCode: 'bar Code', //万能码生成
    Developer: 'Developer', //开发者管理
    TemplateSetting: 'Template Setting', //模板设置
    TemplateDatas: 'Template Datas', //模板内容
    TemplateList: 'Template List', //模板列表
    Push: 'Push', //消息推送
    PushList: 'PushList', //推送列表
    Message: 'Message', //留言反馈
    QualityAppeal: 'Quality Appeal', //质量诉求
    Invoice: 'Invoice', //发票管理
    Setting: 'Setting', //设置管理
    InvoiceList: 'Invoice List', //发票列表
    DeveloperList: 'Developer List', //开发者列表
    AuditList: 'Audit List', //审核列表
    Blacklist: 'Blacklist', //黑名单
    InvoiceSetting: 'Invoice Setting', //发票设置
    InvoiceList: 'Invoice List', //发票管理
    CreateCodeAudit: 'Create Code Audit', //生码规则
    FunctionSetting: 'Function Setting', //套餐设置
    DocsSetting: 'Docs Setting', //文档设置
    UserAgreement: 'User Agreement', //用户协议
    OthersSetting: 'Others Setting', //其他设置
    CodeAllot: 'CodeAllot', //码标分配
    Register: 'OID Register', //OID登记
    DeviceManage: 'Device Manage', //设备管理
    //商户
    HomePage: 'Homepage',  //首页展示
    ProductManage: 'Product Manage',  //产品管理
    CodeManage: 'Code Manage',  //码标管理
    BatchManage: 'Batch Manage',  //批次管理
    CodeActive: 'Code Active',  //码标激活
    Promotion: 'Promotion', //促销活动
    WinningRecord: 'Winning Record', //中奖记录
    WithdrawalRecord: 'Withdrawal Record', //提现记录
    PrizeExchange: 'Prize Exchange', //奖品兑换
    UserStats: 'User Stats', //用户统计
    PromotionSet: 'Promotion Set', //促销设置
    CodePacket: 'Code Packet',  //码标分组
    BarCodePacket: 'Bar Code Packet',  //条形码分组
    ProcessManage: 'Process Manage',  //工序管理
    ProcessList: 'Process List',  //工序列表
    ProcessAllot: 'Process Allot',  //工序分配
    DealerManage: 'Dealer Manage',  //经销商管理
    DealerList: 'Dealer List',  //经销商列表
    DealerShipping: 'Dealer Shipping',  //经销商发货
    DeliveryRecord: 'Delivery Record',  //发货记录
    ReceiptRecord: 'Receipt Record',  //收货记录
    CodeScanQRActive: 'Code Scan QR Active',  //扫码激活
    CodeExtentActive: 'Code Extent Active',  //首尾码激活
    CodeFileActive: 'Code File Active',  //文件激活
    MaterialManage: 'Material Manage',  //素材管理
    ImagesSpace: 'Images Space',  //图片空间
    VideosSpace: 'Videos Space',  //视频空间
    AudioSpace: 'Audio Space',  //音频空间
    Statistics: 'Statistics',  //查询统计
    CodeQuery: 'Code Query',  //码标查询
    GroupQuery: 'Group Query',  //分组查询
    FleeingGoodsList: 'Fleeing Goods List',  //窜货列表
    ScanQRStats: 'Scan QR Stats',  //扫码统计
    ScanQRRecords: 'Scan QR Records',  //扫码记录
    QueryQRRecords: 'Query QR Records',  //查询记录
    MobileQRRecords: 'Mobile QR Records',  //手机记录
    BatchQuery: 'Batch Query',  //批次查询
    Account: 'Account', //账号管理
    BasicInfo: 'BasicInfo', //基本信息
    FunctionPackage: 'Function Package', //生码套餐
    BillingManagement: 'Billing Management', //费用
    IncomeDisbursement: 'Income Disbursement', //收支明细
    InvoiceManage: 'Invoice Manage', //发票管理
    Developer: 'Developer', //开发者中心
    SecuritySettings: 'Security Settings', //安全设置
    Brand: 'Brand', //商标认证
    Market: 'Market', //营销活动
    ShhfBrand: 'Brand', //商标认证
    Licensor: 'Licensor', //授权管理
    Fields: 'Fields', //产地位置
    searchRecord: 'Search Record', //查询记录
    Replace: 'Replace', //码标替换
    //网点
    StockManage: 'Stock Manage', //库存管理
  },
  Column:{
    ClickTo: 'Click to', //点击前往
    ProductManage: 'Product Manage', //产品管理
    AddProduct: 'Add Product', //添加产品
    EditProduct: 'Edit Product', //编辑产品
    DeviceManage: 'Device Manage', //产品管理
    AddDevice: 'Add Device', //添加产品
    EditDevice: 'Edit Device', //编辑设备
    TemplateSettings: 'Template Settings', //模板设置
    TemplateEdit: 'Template Edit', //模板编辑
    BatchManage: 'Batch Manage', //批次管理
    QualityInformation: 'Quality Information', //质检信息
    Brand: 'Brand', //商标管理
    AddBrand: 'Add Brand', //添加商标
    ChangeBrand: 'Change Brand', //修改商标
    ProcessList: 'Process List', //工序列表
    AddProcess: 'Add Process', //添加工序
    EditProcess: 'Edit Process', //编辑工序
    DealerList: 'Dealer List', //经销商列表
    AddDealer: 'Add Dealer', //添加经销商
    EditDealer: 'Edit Dealer', //编辑经销商
    DemoProcess: 'Demo process', //演示溯源
  },
  Common:{
    yes: 'Yes',
    no: 'No',
    Account: 'Account', //账号
    Confirm: 'Confirm', //确定
    Cancel: 'Cancel', //取消
    Search: 'Search', //搜索
    Level: 'Level', //级
    OIDAuthentication: 'OID Authentication', //OID认证
    SecurityAuthentication: 'Security Authentication', //防伪认证
    Name: 'Name', //名称
    Alias: 'Alias', //别名
    Text: 'Text', //文字
    Image: 'Image', //图片
    Title: 'Title', //标题
    State: 'State', //状态
    CreationTime: 'Creation Time', //创建时间
    Operation: 'Operation', //操作
    Edit: 'Edit', //编辑
    Copy: 'Copy', //复制
    Preview: 'Preview', //预览
    Change: 'Change', //修改
    Save: 'Save', //保存
    Back: 'Back', //返回
    Delete: 'Delete', //删除
    Completed: 'Completed', //已完成
    Passed: 'Passed', //已通过
    InProgress: 'In Progress', //处理中
    InReview: 'InReview', //审核中
    Failure: 'Failure', //失败
    Refer: 'Refer', //未通过
    DeviceManage: '',
    Verified: 'Verified', //已认证
    Disabled: 'Disabled', //禁用
    Corvidae: 'Corvidae', //待完善
    Expired: 'Expired', //已失效
    Product: 'Product', //产品
    ProductName: 'Product Name', //产品名称
    VideoLink: 'Video Link', //视频链接
    Amount: 'Amount', //数量
    SuccessAmount: 'Success Amount', //成功数量
    Download: 'Download', //下载
    Type: 'Type', //类型
    SecurityCode: 'Security Code', //防伪码
    SequenceCode: 'Sequence Code', //流水码
    ActivateWay: 'Activate Way', //激活方式
    DefaultActivation: 'Default Activation', //默认激活
    OutboundActivation: 'Outbound Activation', //出库激活
    ResidueCodes: 'Residue Codes', //剩余生码量
    Remarks: 'Remarks', //备注
    Batch: 'Batch', //批次
    Numbering: 'Numbering', //编号
    Scan: 'Scan', //采集
    Extent: 'Extent', //首尾码
    File: 'File', //文件
    ScanActive: 'Scan Active', //采集激活
    ExtentActive: 'Extent Active', //首尾码激活
    FileActive: 'File Active', //文件激活
    ActiveCode: 'Active Code', //激活码标
    CodeNumber: 'Code Number', //码标数量
    FirstCode: 'First Code', //码标首码
    LastCode: 'Last Code', //码标尾码
    CodeFile: 'CodeFile', //码标文件
    CurrentCodeList: 'Current acquisition code', //当前采集列表
    Unit:'',  //个单位
    Clear: 'Clear', //清空
    ActivateNumber: 'Activate Number', //激活数量
    FileUpload: 'File Upload', //上传文件
    FileSizeHint: 'The size of the uploaded file must not exceed 5M', //上传的文件大小不能超过5M
    ClickView: 'Click View', //点击查看
    Linkman: 'Linkman', //联系人
    Phone: 'Phone', //联系电话
    Address: 'Address', //联系地址
    Manage: 'Manage', //管理
    Stock: 'Stock', //库存
    DatasImport:'Data Import', //数据导入
    Infinite: 'Infinite', //无限
    Process: 'Process', //工序
    SuccessfullyQuantity: 'Successfully Quantity', //成功数量
    SelectAll: 'Select All', //全选
    DeleteSelection: 'Delete Selection', //删除选中
    MovePacket: 'Move Packet', //移动分组
    Rename: 'Rename', //重命名
    Function: 'Function', //功能
    Describe: 'Describe', //描述
    Cost: 'Cost', //费用
    ExpirationDate: 'Expiration Date', //到期时间
    CreditCard: 'Credit Card', //未开通
    HaveOpened: 'Have Opened', //已开通
    Buy: 'Buy', //购买套餐
    HistoryBuy: 'History Buy', //上次续费
    NotPackage: 'Not Package', //无套餐
    FreeTrial: 'Free Trial', //免费试用
    WeChatPay: 'WeChat Pay', //微信支付
    S:'s', //秒
    Yuan: 'Yuan', //元
    Settings: 'Settings', //设置
    HaveSet: 'Have Set', //已设置
    NotSet: 'Not Set', //未设置
    GlobalIdentity: 'Global Identity', //全球统一标识
    TotalConsumption: 'Total Consumption', //总消费
    PayTime: 'Count down the order expiration time', //订单到期时间倒计时
    FileHint: 'The list files can be stored in the cloud for one month. For multiple or long-term use, please download the file package for storage', //该文件云端存储时间为一个月，如要多次或长期使用请自行下载文件包进行保管
    Document: 'View operating instructions', //查看操作说明
    YearConsumption: 'Total consumption this year', //年消费
    HintOne: 'Consumption statistics for the past 12 months', //近12个消费统计
    HintTwo: 'For more records, click on the bill details', //如要查看更多消费记录，可点击账单明细查看
    ImageSize: 'The image is less than 2M in JPG, PNG or GIF format', //图片小于2M，格式为 JPG、PNG或GIF
    VideoSize: 'The video is less than 20M and the format is MP4', //视频小于20M，格式为 MP4
  }
}

//管理员
export const a = {
  HomePage:{
    TotalIncome: 'Total Income', //总收益额
    CreationCodeAmount: 'Created Code Amount', //生码总数
    ScanQRAmount: 'Scan QR Amount', //扫码总数
    OrganizationAmount: 'Organization Amount', //组织总数
    BusinessAmount: 'Business Amount', //企业总数
    IndividualAmount: 'Individual Amount', //个体总数
    IncomeStatistics: 'Income Statistics', //收益统计
    IncomeStatisticsHint: 'The current chart shows earnings statistics for the last 12 months', //当前图表显示的是近12个月的收益统计
    RevenueCost: 'Revenue Cost', //收益费用
  }
}

//商户
export const b = {
  FunctionList:{
    ProcessManage:'Process Manage', //工序管理
    ProcessList:'Process List', //工序列表
    ProcessListDescribe:'Add, edit and list the production process', //工序列表描述
    ScanQRDistribution:'Scan QR Distribution', //扫码分配
    ScanQRDistributionDescribe:'Assign codes to the next process through a single collection', //扫码分配描述
    ExtentQRDistribution:'Extent QR Distribution', //首尾码分配
    ExtentQRDistributionDescribe:'Assign a set of codes to the next procedure by means of first and last codes', //首尾码分配描述
    FileQRDistribution:'File QR Distribution', //文件分配
    FileQRDistributionDescribe:'Assign codes to the next process by uploading files', //文件分配描述
    DistributionList:'Distribution List', //分配列表
    DistributionListDescribe:'View the list and data of process assignments', //分配列表描述
    DealerManage:'Dealer Manage', //经销商管理
    DealerList:'Dealer List', //经销商列表
    DealerListDescribe:'Dealer additions, edits, and lists', //经销商列表描述
    ScanQRShipments:'Scan QR Shipments', //扫码发货
    ScanQRShipmentsDescribe:'Shipment to distributor by single collection', //扫码发货描述
    ExtentQRShipments:'Extent QR Shipments', //首尾码发货
    ExtentQRShipmentsDescribe:'Send a set of goods to the dealer by first and last codes', //首尾码发货描述
    FileQRShipments:'File QR Shipments', //文件发货
    FileQRShipmentsDescribe:'Send the goods to the dealer by uploading the file', //文件发货描述
    ShipmentsList:'Shipments List', //发货列表
    ShipmentsListDescribe:'View shipping list and data', //发货列表描述
  },
  HomePage:{
    CreationCodeAmount: 'Created Code Amount', //生码总数
    ActiveAmount: 'Active Amount', //激活总数
    ScanQRAmount: 'Scan QR Amount', //扫码总数
    ProductsAmount: 'Products Amount', //产品总数
    ProcessAmount: 'Process Amount', //工序总数
    DealerAmount: 'Dealer Amount', //经销总数
    CodeActivationStatistics: 'Code Activation Statistics', //码标激活统计
    CodeActivationStatisticsDescribe: 'The current chart only records activation data for one week. For more information, please see the statistical query', //码标激活统计描述
    ChinaScanQRStat: 'China Scan QR Stat', //扫码统计
    ChinaScanQRStatDescribe: 'Total scanning code of all products', //扫码统计描述
    Xpense: 'Xpense', //消费
    Referer: 'Referer', //访问来源
    ToActivateTheNumber: 'To Activate The Number', //激活个数
    XpenseTracker: 'Xpense Tracker', //消费记录
    XpenseTrackerDescribe: 'Hover the mouse over the point to view details', //消费记录描述
    Guangdong: 'Guangdong', //广东
    Zhejiang: 'Zhejiang', //浙江
    Fujian: 'Fujian', //福建
    Shanghai: 'Shanghai', //上海
    Hunan: 'Hunan', //湖南
    ExpenseCenter: 'Expense Center', //费用中心
    BillingDetails: 'Billing Details', //账单明细
    Balance: 'Balance', //账户余额
    Recharge: 'Recharge', //充值
    Withdraw: 'Withdraw', //提现
    BasicEdition: 'Basic Edition', //基础版
    PayTheTemplate: 'Pay The Template', //付费模块
    InvoiceManagement: 'Invoice Management', //发票管理
    InvoicedAmount: 'Invoiced Amount', //可开发票金额
    SetInvoiceTitle: 'Set Invoice Title', //设置发票抬头
    InvoiceMailingAddress: 'Invoice Mailing Address', //设置发票地址
    AskingForInvoice: 'Asking For Invoice', //索取发票
  },
  PromotionSet:{
    PromotionSet: 'Promotional activity feature Settings', //促销活动功能设置
    IsWSA: 'Is it mandatory to pay attention to weChat service account', //是否强制关注公众号
    IsWSAHint: 'Need to follow the public number in order to receive red envelopes', //需要关注公众号后才能领红包
    IsWithdrawalAudit: 'Withdrawal Audit', //是否提现审核
    IsWithdrawalAuditHint: 'The withdrawal can be received only with the approval of the administrator',  //提现需要管理员审核允许才能到账
    IsIntegral: 'Whether to scan the code to get the integral', //是否扫码获取积分
    IsIntegralHint: 'After the code is turned on, the integral will be obtained', //开启后扫码将获取积分
    IsGetUserPhone: 'Whether to get the users phone number',  //是否获取用户电话号码
    IsGetUserPhoneHint: 'Get the phone number to get the red envelope (￥0.1 for each text message)', //获取手机号码才能领取红包（需要收取短信费用每条0.1元）
    Withdrawal: 'Cash withdrawal amount', //提现金额
    WithdrawalHint: 'Set the minimum and maximum amount for withdrawal', //提现的最小金额和最大金额设置
    WithdrawalHintOne: 'Minimum withdrawal amount', //最小可提现金额
    WithdrawalHintTwo: 'Maximum withdrawal amount', //最大可提现金额
    Remind: 'Insufficient balance SMS reminder', //余额不足短信提醒
    RemindHint: 'Do not set the phone number and balance default to no SMS reminder', //不设置手机号和余额默认为不短信提醒
    RemindHintOne: 'Text message to remind the phone number', //短信提醒手机号
    RemindHintTwo: 'Less than the balance after the reminder', //小于多少余额后提醒
  },
  ProductManage:{
    AddProduct: 'Add Product', //添加产品
    EditProduct: 'Edit Product', //编辑产品
    DataImport: 'Data Import', //数据导入
    SearchHint: 'Enter the product name query', //输入产品名称查询
    TemplateSettings: 'Template Settings', //模板设置
    ShelfLife: 'Shelf Life', //保质期
    Specification: 'Specification', //规格
    Score: 'score', //积分
    Keyword: 'Keyword', //关键词
    TraceRatio: 'Trace Ratio', //溯源率
    AddLine: 'Add Custom Attribute', //添加自定义属性
    TemplateSelect: 'Template Select', //选择模板
    CancelSelect: 'Cancel Select', //取消选择
    CommonTemplate: 'Common Template', //普通模板
    AntiFakeTemplate: 'AntiFake Template', //防伪模板
    PromotionTemplate: 'Promotion Template', //促销模板
    IsShowModule: 'Whether to display the current module', //是否显示当前模块
    SeeEffect: 'See the effect of scanning code', //查看扫码效果
    LineHint: 'More custom attribute content can be added based on product requirements', //可根据产品的需求添加更多自定义的属性内容
    HintOne: 'Please enter the product name', //请输入产品名称
    HintTwo: 'Product shelf life can be entered', //可输入产品保质期
    HintThree: 'Product specification can be entered', //可输入产品规格
    HintFour: 'Product describe can be entered', //可以输入产品描述
    HintFive: 'Example: the price', //自定义名称 例：价格
    HintSix: 'Example: 99 yuan', //自定义内容 例：99元
  },
  DeviceManage:{
    AddDevice: 'Add Device', //添加设备
    EditDevice: 'Edit Device', //编辑设备
    SearchHint: 'Enter the device name query', //输入设备名称查询
  },
  CodeManage:{
    no: 'No',
    CreateCodes: 'Create Codes', //生成码标
    CreateBarCodes: 'Create Bar Codes', //生成条码
    QRPrinting: 'QR Printing', //二维码印刷
    ProductOption: 'Product Option', //产品选择
    CodeLevel: 'Code Level', //码标等级
    CodeNumber: 'Code Number', //码标数量
    BarCodeNumber: 'Bar Code Number', //条码数量
    Figure: 'Figure（Length is 6）', //数字
    EnglishFigures: 'English plus figures（Length is 6）', //英文加数字
    Monocode: 'Monocode', //单码
    Casecode: 'Casecode', //套码
    BoxCodeNumber: 'Box code number', //箱码数量
    EachBoxNumber: 'Each box number', //每箱个数
    ProductionType: 'Production Type', //生产类型
    HolderCertificate: 'Holder of pesticide registration certificate', //农药登记证持有人
    ManufacturingConsignment: 'Manufacturing Consignment', //委托加工
    EntrustRepackaging: 'Entrust the repackaging', //委托分装
    HintOne: 'China qr code certification center global standard', //中国二维码认证中心 全球标准
    HintTwo: 'The default mode generation can be used, manual activation requires the background, APP, small program collection activation', //默认方式生成即可用，手动激活需要后台、APP、小程序采集等方式激活
    HintThree: 'Need security code query function', //是否需要防伪码查询真伪功能
    HintFour: 'Whether flow codes are needed to distinguish the order of labels', //是否需要流水码用于区分标签的顺序
    HintFive: 'Whether the default box function is required, single code can be late box or no box', //是否需要默认组箱功能，单码可后期组箱或者不组箱
    HintSix: 'High grade code can be set to low grade code example :1 2 grade code set 10 1 grade code 1 drag 10', //高等级码可以组低等级的码 例:1个2级码组10个1级码 为1拖10
    HintSeven: 'Code code generation number is determined by the code package', //码标生成数由生码套餐决定
    HintEight: 'Default activation require first selecting the product for binding' ,//默认激活需要先选择产品进行绑定
    HintNine : 'Check the package', //查看生码套餐
    HintEleven: 'Example: fill in 10 and 10 boxes will be generated', //例：填写10那么就会生成10个箱码
    HintTwelve: 'Example: how many subcodes of a box example: if you fill in 10, it is 10 subcodes per box, which is 1 drag 10', //例：1箱多少个子码 例：填写10则是每箱10个子码，为1拖10
    HintThirteen: 'Generate up to 1 million tokens in a single pass', //单次生成最多100万个码标
  },
  ShhfBrandManage:{
    AddBrand: 'Add Batch', //添加品牌
    BrandEdit: 'Brand Edit', //品牌编辑
    BrandName: 'Brand Name', //品牌名称
  },
  ShhfLicensorManage:{
    AddLicensor: 'Add Batch', //添加授权
    LicensorEdit: 'Licensor Edit', //授权编辑
    LicensorName: 'Licensor Name', //授权名称
    FieldsName: 'Fields Name', //产地名称
  },
  BatchManage:{
    BatchName: 'Batch Name', //批次名称
    AddBatch: 'Add Batch', //添加批次
    QualityStatus: 'Quality Status', //质检状态
    QualityInformation: 'Quality Information', //质检信息
    LogisticsStatus: 'Logistics Status', //物流状态
    LogisticsInformation: 'Logistics Information', //物流信息
    LogisticsType: 'Logistics Type', //物流类型
    LogisticsOrderID: 'Logistics OrderID', //物流单号
    SFExpress: 'SF-Express', //顺丰快递
    STOExpress: 'STO-Express', //申通快递
    YTOExpress: 'SYTO-Express', //圆通快递
    YunDaExpress: 'YunDa-Express', //韵达快递
    EMSExpress: 'EMS-Express', //邮政快递
    HintOne: 'Enter a batch name query', //输入批次名称查询
    HintTwo: 'Please enter a custom batch name', //请输入自定义批次名称
    HintThree: 'Note information can be entered', //可输入备注信息
    HintFour: 'Please enter the tracking number', //请输入物流单号
    HintFive: 'Custom text and pictures can be added according to the requirements of quality information', //可根据质检信息的需求添加自定义文字和图片
    HintSix: 'Example: quality control time', //例：质检时间
    HintSeven: 'Example: the 2017-10-31', //例：2017-10-31
  },
  CodeActive:{
    CodeActive: 'Code Active', //码标激活
    ReplaceData: 'Replace Data', //替换数据
    ViewDetails: 'View Details', //查看详情
    HintOne: 'It can be divided into three ways: collection activation, interval activation and file activation', //分为采集激活、首尾码激活和文件激活三种方式
    HintTwo: 'Batch binding quality control information, logistics information and other contents, if you want to customize please add in the batch management first', //批次可绑定质检信息，物流信息等内容，如要自定义请在批次管理里先添加
    HintThree: 'Select the product activated by the code', //选择码标激活的产品
    HintFour: 'Type the code label in the input box and press enter to add it', //在输入框里键入码标后按回车添加，可采集多个一并提交
    HintFive: 'Type the code to activate here', //在此键入需要激活的码标
    HintSix: 'Here type the header code that you want to activate', //在此键入需要激活的码标首码
    HintSeven: 'Here type the code trailing code that you want to activate', //在此键入需要激活的码标尾码
  },
  CodePacket:{
    CodeGrouping: 'Code Grouping', //码标分组
    CombinationType: 'Combination Type', //组合类型
    NumberCombination: 'Number of combination', //组合数
    NumberSuccessfulCombinations: 'Number of successful combinations', //成功组合数
    PacketMode: 'Packet Mode', //分组方式
    ScanPacket: 'Scan Packet', //采集分组
    ExtentPacket: 'Extent Packet', //首尾码分组
    FilePacket: 'File Packet', //文件分组
    OldFilePacket: 'Old File Packet', //文件分组
    externalFilePacket: 'External File Packet', //外部文件分组
    SeniorCode: 'Senior Code', //高级码
    InferiorCode: 'Inferior Code', //低级码
    InferiorFirstCode: 'Inferior First Code', //低级首码
    InferiorLastCode: 'Inferior Last Code', //低级尾码
    SeniorFile: 'Senior File', //高级文件包
    InferiorFile: 'Inferior File', //低级文件包
    File: 'File', //文件包
    GroupNumber: 'The number of each group of', //每组个数
    HintOne: 'It can be divided into collection grouping, interval grouping and file grouping', //分为采集分组、首尾码分组和文件分组三种方式
    HintTwo: 'You can only add one at a time before committing. Press enter to add to the list', //每次确定提交前仅能添加一个，按回车添加至列表
    HintThree: 'Type the senior code you want to group here', //在此键入需要分组的高级码
    HintFour: 'Here type the inferior code that you want to group with the senior code', //在此键入需要跟高级码分组的低级码
    HintFive: 'Here type the inferior header that you want to group', //在此键入需要分组的低级首码
    HintSix: 'Here type the inferior end code that you want to group', //在此键入需要分组的低级尾码
    HintSeven: 'Please enter the number of each group', //请输入每组个数
  },
  Brand:{
    Brand: 'Brand', //商标
    AddBatch: 'Add Batch', //添加商标
    ChangeBrand: 'Change Brand', //修改商标
    BrandName: 'Brand Name', //商标名称
    BrandCertificate: 'Brand Certificate', //商标证书
    Authorization: 'Authorization', //授权书
    BindingProduct: 'Binding Product', //绑定产品
    Certificate:'Certificate', //证书信息
    HintOne: 'Please select the product to bind to', //请选择需要绑定的产品
    HintTwo: 'Please select the trademark corresponding to the product for review', //请选择与产品对应的商标进行审核
    HintThree: 'Please enter the trade name', //请输入商标名称
    HintFour: 'Both the trademark certificate and the letter of authorization must be uploaded', //商标证书和授权书有一样必须上传
  },
  Process:{
    AddProcess: 'Add Process', //添加工序
    DistributorDelivery: 'Distributor Delivery', //经销商发货
    BatchManage: 'BatchManage', //批次管理
    ReceivingProcess: 'Receiving Process', //收货工序
    ProcessAllot: 'Process Allot', //工序分配
    AllotType: 'Process Type', //分配方式
    AllotCodes: 'Allot Codes', //码标分配
    ScanAllot: 'Scan Allot', //采集分配
    ExtentAllot: 'Extent Allot', //首尾码分配
    FileAllot: 'File Allot', //文件分配
    HintOne: 'Enter the process name query', //输入工序名称查询
    HintTwo: 'It can be divided into three ways: collection Allot, interval Allot and file Allot', //分为采集分配、首尾码分配和文件分配三种方式
    HintThree: 'Select the product allot to the code', //选择码标分配的产品
    HintFour: 'Select the process of code allot', //选择码标分配的工序
    HintFive: 'Here type the code that you want to allot', //在此键入需要分配的码标
    HintSix: 'Here type the header code that you want to allot', //在此键入需要分配的码标首码
    HintSeven: 'Here type the code trailing code that you want to allot', //在此键入需要分配的码标尾码
  },
  Dealer:{
    AddDealer: 'Add Dealer', //添加经销商
    CodesShipping: 'Codes Shipping', //码标发货
    ShippingCodes: 'Shipping Codes', //码标发货
    ShippingType: 'Shipping Type', //发货方式
    CreationDate: 'Creation Date', //生产时间
    Nodate: 'No Date', //无时间
    CurrentDate: 'Current Date', //当前时间
    CustomDate: 'Custom Date', //自定义日期
    ScanShipping: 'Scan Shipping', //采集发货
    ExtentShipping: 'Extent Shipping', //首尾码发货
    FileShipping: 'File Shipping', //文件发货
    ReceivingDealer: 'Receiving Dealer', //收货经销商
    ShippingDealer: 'Shipping Dealer', //发货经销商
    ShippingOutlets: 'Shipping Outlets', //发货网点
    HintOne: 'Enter the dealer name query', //输入经销商名称查询
    HintTwo: 'It is divided into three ways: collection and delivery, range delivery and document delivery', //分为采集发货、首尾码发货和文件发货三种方式
    HintThree: 'Choose products that are shipped by code', //选择码标发货的产品
    HintFour: 'Select the receiving dealer', //选择收货的经销商
    HintFive: 'Type the code you want to ship here', //在此键入需要发货的码标
    HintSix: 'Here type the code header that you want to ship', //在此键入需要发货的码标首码
    HintSeven: 'Here type the end code that you want to ship', //在此键入需要发货的码标尾码
    HintEight: 'Please select a time', //请选择时间
    HintNine: 'The production date will be displayed on the scan code display page', //生产日期将显示在扫码展示页面
  },
  Material:{
    UploadImages: 'Upload Images', //上传图片
    UploadAudio: 'Upload Audio', //上传音频
    UploadVideo: 'Upload Video', //上传视频
    GroupingManage: 'Grouping Manage', //分组管理
    StorageSpace: 'Storage Space', //存储空间
    UsedStorage: 'Used Storage', //已使用空间
    SurplusStorage: 'Surplus Storage', //剩余空间
    Loading: 'Loading spatial storage data', //正在加载空间存储数据
    ImageSizeHint: 'The size of the uploaded image shall not exceed 2M, and the image size shall be compressed', //上传的图片大小不超过2M，压缩图片大小方式
    AudioSizeHint: 'Upload audio size not more than 5M, compressed audio size', //上传的音频大小不超过5M，压缩音频大小方式
    VideoSizeHint: 'The size of the uploaded video should not exceed 10M, and the video size should be compressed', //上传的视频大小不超过10M，压缩视频大小方式
  },
  BasicInfo:{
    Regdate: 'Regdate', //注册时间
    OIDNumber: 'OID Number', //OID编号
    MerchantNumber: 'Merchant Number', //商户编号
    UploadLogo: 'Upload Logo', //上传LOGO
    BusinessInformation: 'Business Information', //商户信息
    FunctionList: 'Function List', //功能列表
    Developer: 'Developer', //开发者中心
    BusinessType: 'Business Type', //商户类型
    BusinessName: 'Business Name', //商户名称
    BusinessLicense: 'Business License', //营业执照
    UploadLicense: 'Upload License', //上传执照
    Industrial: 'Industrial', //所属行业
    CreditCode: 'Credit Code', //信用代码
    BusinessIntroduction: 'Business Introduction', //商户简介
  },
  FuncitonPackage:{
    HintOne: 'Note: the qr code for this order is invalid', //注意：该订单二维码已失效
    WeChatScanPay: 'WeChat Scan Pay', //微信扫码支付
  },
  Statistics:{
    FunctionPackage: 'Function Package', //生码套餐
    FunctionModule: 'Function Module', //功能模块
    Promotion: 'Promotion', //促销红包
    ConsumptionType: 'Consumption Type', //消费类型
    Monetary: 'Monetary', //消费金额
  },
  SecuritySettings:{
    SecurityLevel: 'Security Level', //安全级别
    SecuritySet: 'Security Settings', //完全设置
    CompletionSchedule: 'Safe completion schedule', //完成进度
    LoginPassword: 'Login Password', //登录密码
    BindEmail: 'Bind Email', //绑定邮箱
    BindingPhone: 'Binding Phone', //绑定手机
    BindingWeChat: 'Binding WeChat', //绑定微信
    SafetyProblem: 'Safety Problem', //安全问题
    Unsubscribe: 'Unsubscribe', //注销用户
    HintOne: 'A secure password can make your account more secure.It is recommended that you change your password regularly and set a password that contains at least two items in letters, symbols or Numbers and is longer than six digits.', //安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个包含字母，符号或数字中至少两项且长度超过6位的密码。
    HintTwo: 'You have bound the mailbox', //您已绑定了邮箱
    HintThree: '[All kinds of system, activity and service notices will be sent to your mailbox]', //链接OID发送的各类系统、活动、服务通知将发送到您的邮箱
    HintFour: 'You have bound your phone', //您已绑定了手机
    HintFive: '[Your mobile phone is a secure mobile phone that can retrieve the password but cannot be used for login]', //您的手机为安全手机，可以找回密码，但不能用于登录
    HintSix: 'Used for scanning code login, balance withdrawal and message push', //用于扫码登录、余额提现和消息推送
    HintSeven: 'It is recommended that you set up three easy to remember, and the most difficult to be obtained by others questions and answers, more effective protection of your password security.', //建议您设置三个容易记住，且最不容易被他人获取的问题及答案，更有效保障您的密码安全。
    HintEight: 'If you no longer use this account, you can log it out.After the account is successfully cancelled, all services, data and privacy information will be deleted and cannot be recovered.', //如果您不再使用此账号，可以将其注销。账号成功注销后，其下所有服务、数据及隐私信息将会被删除并将无法恢复。
  }
}
